<template>
  <div class="menu">
    <div class="header">
      <a-button type="primary" @click="openAdd">添加菜单</a-button>
    </div>

    <div style="padding: 15px;padding-top:90px">
      <div class="menu-content">
        <a-table
          :columns="columns"
          :row-key="record => record.code"
          :data-source="list"
          :pagination="false"
          :loading="loading"
          :scroll="{ y: tableHeight }"
        >
          <template slot="p_code" slot-scope="p_code,record">
            <a-select
              :default-value="record.p_code"
              style="width: 200px"
              @change="p_code => changeParent(p_code, record.code, record)"
            >
              <a-select-option value>无</a-select-option>
              <a-select-option
                :value="el.code"
                v-for="el in parentList"
                :key="el.code"
                :disabled="el.code === record.code"
              >{{ el.name }}</a-select-option>
            </a-select>
          </template>

          <template slot="option" slot-scope="option,record">
            <a-button type="link" size="small" @click="openSEO(record)">seo设置</a-button>
            <a-button
              type="link"
              size="small"
              @click="toContent(record)"
              v-if="record.p_code"
            >内容设置</a-button>
            <a-button type="link" size="small" v-if="!record.p_code" @click="openCover(record)">顶图设置</a-button>
            <a-button type="link" size="small" style="color: #409EFF" @click="openAdd(record)">编辑</a-button>
            <a-popconfirm
              title="确定要删除该数据?"
              ok-text="确定"
              cancel-text="我再想想"
              @confirm="remove(record.code)"
            >
              <a-button type="link" :loading="loadingRemove === record.code" style="color: red">删除</a-button>
            </a-popconfirm>
          </template>
        </a-table>
      </div>
    </div>

    <a-modal
      title="顶图设置"
      :visible="isShowModal"
      :confirm-loading="loadingCover"
      @ok="saveCover"
      @cancel="isShowModal = false"
      cancel-text="取消"
      ok-text="确定"
    >
      <div class="modal-row">
        <p class="modal-label">页面名称：</p>
        {{ coverItem.name }}
      </div>
      <div style="display: flex">
        <p class="modal-label">图片：</p>
        <a-upload
          accept="image/*"
          :before-upload="upload"
          :show-upload-list="false"
          :disabled="uploading"
        >
          <div v-if="coverItem.top_pic.img" class="upload-image-box">
            <img
              :src="coverItem.top_pic.img"
              class="upload-img"
              @error="coverItem.top_pic.img = ''"
            />
            <a-icon type="delete" class="delete-icon" @click.stop="coverItem.top_pic.img = ''" />
          </div>
          <a-button v-else :loading="uploading">上传</a-button>
        </a-upload>
      </div>
      <div style="display: flex">
        <p class="modal-label"></p>
        <p class="upload-tips">仅支持上传一张格式为png、jpg图片，最大不超过2m；建议尺寸 1920*400</p>
      </div>
    </a-modal>

    <a-modal
      title="SEO设置"
      :visible="isShowSEOModal"
      :confirm-loading="loadingSeo"
      @ok="saveSEO"
      @cancel="isShowSEOModal = false"
      cancel-text="取消"
      ok-text="确定"
    >
      <a-form-model :model="seoItem" :label-col="{ span: 5 }" :wrapper-col="{ span: 19 }">
        <a-form-model-item label="自定义标题" prop="seo_title">
          <a-input v-model="seoItem.seo_title" placeholder="自定义标题" />
        </a-form-model-item>

        <a-form-model-item prop="seo_key_words" label="关键词">
          <a-input v-model="seoItem.seo_key_words" placeholder="关键词" />
        </a-form-model-item>

        <a-form-model-item prop="seo_desc" label="描述">
          <a-input v-model="seoItem.seo_desc" placeholder="描述" />
        </a-form-model-item>
      </a-form-model>
    </a-modal>

    <a-modal
      title="添加菜单"
      :visible="isShowAdd"
      :confirm-loading="loadingAdd"
      @ok="add"
      @cancel="isShowAdd = false"
      cancel-text="取消"
      ok-text="确定"
    >
      <a-form-model
        ref="ruleForm"
        :model="form"
        :rules="rules"
        :label-col="{ span: 5 }"
        :wrapper-col="{ span: 19 }"
      >
        <a-form-model-item prop="name" label="页面名称">
          <a-input v-model="form.name" placeholder="页面名称" />
        </a-form-model-item>

        <a-form-model-item prop="href" label="导航链接">
          <a-input v-model="form.href" placeholder="导航链接" />
        </a-form-model-item>

        <a-form-model-item prop="p_code" label="父级菜单">
          <a-select :default-value="form.p_code" @change="val => form.p_code = val" v-if="isShowAdd">
            <a-select-option value>无</a-select-option>
            <a-select-option
              :value="item.code"
              v-for="item in parentList"
              :key="item.code"
              :disabled="item.code === form.code"
            >{{ item.name }}</a-select-option>
          </a-select>
        </a-form-model-item>

        <a-form-model-item prop="sort" label="排序">
          <a-input v-model="form.sort" placeholder="排序" type="number" />
        </a-form-model-item>

        <a-form-model-item prop="is_rich_text" label="富文本页面">
          <a-switch
            :default-checked="form.is_rich_text === '1'"
            @change="val => form.is_rich_text = val ? '1' : '0'"
          />
        </a-form-model-item>

        <a-form-model-item label="自定义标题" prop="seo_title">
          <a-input v-model="form.seo_title" placeholder="自定义标题" />
        </a-form-model-item>

        <a-form-model-item prop="seo_key_words" label="关键词">
          <a-input v-model="form.seo_key_words" placeholder="关键词" />
        </a-form-model-item>

        <a-form-model-item prop="seo_desc" label="描述">
          <a-input v-model="form.seo_desc" placeholder="描述" />
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import { getNavList, setNavSeo, updateNavParent, updateNavBanner, addNav, updateNav, removeNav } from '@/api/admin'
import { Select, Upload } from 'ant-design-vue'
import upload from '@/plugins/upload'

export default {
  data () {
    return {
      list: [],
      seoCode: '',
      loadingSeo: false,
      isShowModal: false,
      loadingCover: false,
      coverItem: {
        top_pic: {}
      },
      uploading: false,
      isShowAdd: false,
      loadingAdd: false,
      form: {
        name: '',
        href: '',
        sort: 1,
        is_rich_text: '0', // 0:不是富文本 1:是富文本
        content: '',
        p_code: '',
        seo_title: '',
        seo_key_words: '',
        seo_desc: ''
      },
      rules: {
        name: [
          { required: true, message: '请填写页面名称', trigger: 'change' }
        ],
        href: [{ required: true, message: '请填写导航链接', trigger: 'change' }]
      },
      parentList: [],
      loadingRemove: '',
      loading: false,
      columns: [
        {
          title: '导航名称',
          dataIndex: 'name'
        },
        {
          title: '导航链接',
          dataIndex: 'href'
        },
        {
          title: '父导航',
          dataIndex: 'p_code',
          scopedSlots: { customRender: 'p_code' }
        },
        {
          title: '浏览量',
          dataIndex: 'view_count',
          width: '150px'
        },
        {
          title: '操作',
          dataIndex: 'option',
          scopedSlots: { customRender: 'option' },
          width: '310px',
          align: 'center'
        }
      ],
      tableHeight: 0,
      isShowSEOModal: false,
      seoItem: {}
    }
  },
  components: {
    [Select.name]: Select,
    [Select.Option.name]: Select.Option,
    [Upload.name]: Upload
  },
  created () {
    this.getList()
  },
  mounted () {
    this.tableHeight = document.body.clientHeight - 260
  },
  methods: {
    handleTableChange (e) {
      this.pagination.current = e.current
      this.getList()
    },
    toContent (item) {
      this.$router.push({
        name: 'MenuContent',
        query: {
          code: item.code,
          p_code: item.p_code
        }
      })
    },
    getList () {
      this.loading = true
      getNavList({ code: '' }).then(list => {
        this.list = list
        this.parentList = list.filter(el => !el.p_code)
        this.loading = false
      }).catch(err => {
        console.error(err)
        this.loading = false
      })
    },
    openSEO (item) {
      this.seoItem = item
      this.isShowSEOModal = true
    },
    saveSEO () {
      const item = this.seoItem
      this.loadingSeo = true
      setNavSeo({
        code: item.code,
        seo_desc: item.seo_desc,
        seo_key_words: item.seo_key_words,
        seo_title: item.seo_title
      }).then(() => {
        this.$message.success('保存成功')
        this.loadingSeo = false
        this.isShowSEOModal = false
        this.getList()
      }).catch(err => {
        this.loadingSeo = false
        this.isShowSEOModal = false
        this.$message.error(err.msg)
      })
    },
    cancelSaveSEO () {
      this.seoCode = ''
      this.getList()
    },
    changeParent (parentCode, code, item) {
      updateNavParent({
        p_code: parentCode,
        code
      }).then(res => {
        this.$message.success('保存成功')
        item.p_code = parentCode
      }).catch(err => {
        this.$message.error(err.msg)
      })
    },
    openCover (item) {
      this.coverItem = Object.assign({}, item)
      this.isShowModal = true
    },
    saveCover () {
      this.loadingCover = true
      updateNavBanner({
        page_code: this.coverItem.code,
        img: this.coverItem.top_pic.img
      }).then(res => {
        this.loadingCover = false
        this.$message.success('保存成功')
        this.getList()
        this.isShowModal = false
        this.coverItem = {
          top_pic: {}

        }
      }).catch(err => {
        this.loadingCover = false
        this.$message.error(err.msg)
      })
    },
    upload (file) {
      if (file.size > 2 * 1024 * 1024) {
        return this.$message.warning('图片最大不超过2m')
      }
      this.uploading = true
      upload(file).then(res => {
        this.coverItem.top_pic.img = res
        this.uploading = false
      }).catch(err => {
        this.uploading = false
        this.$message.error(err.msg)
      })

      return false
    },
    openAdd (item) {
      this.isShowAdd = true
      if (item.code) {
        this.form = Object.assign({}, item)
      } else {
        this.form = {
          name: '',
          href: '',
          sort: 1,
          is_rich_text: '0', // 0:不是富文本 1:是富文本
          content: '',
          p_code: '',
          seo_title: '',
          seo_key_words: '',
          seo_desc: ''
        }
      }
    },
    add () {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          this.loadingAdd = true
          if (this.form.code) {
            updateNav({
              code: this.form.code,
              name: this.form.name,
              href: this.form.href,
              p_code: this.form.p_code,
              is_rich_text: this.form.is_rich_text,
              sort: this.form.sort,
              seo_title: this.form.seo_title,
              seo_key_words: this.form.seo_key_words,
              seo_desc: this.form.seo_desc
            }).then(res => {
              this.loadingAdd = false
              this.isShowAdd = false
              this.$message.success('添加成功')
              this.getList()
            }).catch(err => {
              this.loadingAdd = false
              this.$message.error(err.msg)
            })
          } else {
            addNav(this.form).then(res => {
              this.loadingAdd = false
              this.isShowAdd = false
              this.$message.success('添加成功')
              this.getList()
            }).catch(err => {
              this.loadingAdd = false
              this.$message.error(err.msg)
            })
          }
        }
      })
    },
    remove (code) {
      this.loadingRemove = code
      removeNav({
        code
      }).then(res => {
        this.loadingRemove = ''
        this.getList()
        this.$message.success('删除成功')
      }).catch(err => {
        this.loadingRemove = false
        this.$message.error(err.msg)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.menu {
  .header {
    position: fixed;
    background: #fff;
    right: 0;
    left: 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 60px;
    padding: 0 30px;
    z-index: 1;
  }

  .menu-content {
    background: #fff;
    padding: 15px;

    .menu-item {
      margin-bottom: 30px;
      padding-bottom: 15px;
      border-bottom: 1px solid #f1f1f1;

      .menu-item-header {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        padding-bottom: 10px;
      }
      .title {
        font-size: 18px;
        font-weight: bold;
        padding-right: 10px;
        position: relative;

        &::before {
          content: "";
          position: absolute;
          top: 50%;
          left: -15px;
          transform: translateY(-50%);
          background: #55b98a;
          height: 20px;
          width: 5px;
        }
      }

      .row {
        padding: 5px 0;
        padding-left: 30px;

        .row-title {
          font-weight: bold;
          padding-bottom: 5px;
        }
      }

      .item-footer {
        display: flex;
        justify-content: flex-end;
      }
    }
  }
}

.modal-label {
  display: inline-block;
  width: 80px;
  padding: 10px 0;
  text-align: right;
  margin-right: 10px;
}

.upload-image-box {
  display: flex;
  align-items: flex-end;
  font-size: 20px;

  .upload-img {
    width: 200px;
    margin-right: 10px;
  }
  .delete-icon {
    cursor: pointer;
    color: red;
  }
}

.upload-tips {
  padding-left: 5px;
  padding-top: 10px;
  font-size: 12px;
  color: #999;
}
</style>
